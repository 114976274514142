import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Upload } from 'tus-js-client';
import {
    Grow,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { Stop, Restore } from '@mui/icons-material';
import {
    CircularProgress,
    IconButton,
    Modal,
    Backdrop,
    Fade,
    Button,
    Dialog,
    Zoom,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Box,
    Container,
    Paper,
    Divider,
    Chip,
    useTheme
} from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import { PlayArrow, CloudUpload, Delete, Add } from '@mui/icons-material';
import SubscriptionsRoundedIcon from '@mui/icons-material/SubscriptionsRounded';
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";

let socket = io('https://streamneo.com');

function Billing() {
    const theme = useTheme();

    const [newSlot, setNewSlot] = useState({
        title: "",
        quality: "720p",
        duration: "1_day"
    });
    const [slots, setSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState('');
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [title, setTitle] = useState("");
    const [videos, setVideos] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState("");
    const [streamKey, setStreamKey] = useState("");
    const [slotName, setSlotName] = useState("");
    const [loading, setLoading] = useState(true);
    const { error, isLoading, Razorpay } = useRazorpay();

    useEffect(() => {
        fetchSlots();
        fetchVideos();
    }, []);

    const fetchSlots = async () => {
        try {
            const response = await fetch("https://streamneo.com/user/slots", {
                headers: { Authorization: localStorage.getItem("token") }
            });
            const data = await response.json();
            setSlots(data);
        } catch (error) {
            console.error("Error fetching slots:", error);
        }
        finally {
            setLoading(false);
        }
    };

    const fetchVideos = async () => {
        try {
            const response = await fetch('https://streamneo.com/user-videos', {
                headers: { 'Authorization': `${localStorage.getItem("token")}` }
            });
            if (response.ok) {
                const data = await response.json();
                setVideos(data.videos);
            } else {
                throw new Error('Failed to fetch videos');
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
    };

    const handleAddSlot = async () => {
        setUploading(true);
        try {
            const response = await fetch("https://streamneo.com/slots", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token")
                },
                body: JSON.stringify(newSlot)
            });
            try {
                const result = await response.json();
                console.log(result);

                // Step 1: Create a Razorpay order
                const orderResponse = await fetch("https://streamneo.com/create-order", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("token"),
                    },
                    body: JSON.stringify({
                        slotId: result.slot._id, // Replace with actual slot ID if available
                        duration: newSlot.duration,
                    }),
                });

                const orderData = await orderResponse.json();
                if (!orderResponse.ok) throw new Error(orderData.message);

                const { order, paymentId } = orderData;

                // Step 2: Configure Razorpay options
                const options = {
                    key: "rzp_test_IUYIAAIps3HYr2", // Replace with your Razorpay Key ID
                    amount: order.amount,
                    currency: order.currency,
                    name: "StreamNeo",
                    description: "Purchase Stream",
                    order_id: order.id,
                    handler: async (response) => {
                        // Step 3: Verify the payment
                        try {
                            const verificationResponse = await fetch("https://streamneo.com/verify-payment", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: localStorage.getItem("token"),
                                },
                                body: JSON.stringify({
                                    paymentId: response.razorpay_payment_id,
                                    orderId: response.razorpay_order_id,
                                    signature: response.razorpay_signature,
                                }),
                            });

                            const verificationData = await verificationResponse.json();
                            if (!verificationResponse.ok) throw new Error(verificationData.message);

                            // Close the form and refresh slots
                            setOpen(false);
                            fetchSlots();
                            alert("Stream purchased successfully!");
                        } catch (error) {
                            console.error("Payment verification failed:", error);
                            alert("Payment verification failed. Please try again.");
                        }
                    },
                    prefill: {
                        name: localStorage.getItem("name"), // Replace with actual user data
                        email: localStorage.getItem("email"), // Replace with actual user data
                        contact: localStorage.getItem("mobile"), // Replace with actual user data
                    },
                    theme: {
                        color: "#3399cc",
                    },
                };

                // Step 4: Open Razorpay Checkout
                const razorpay = new Razorpay(options);
                razorpay.open();
            } catch (error) {
                console.error("Error creating slot or initiating payment:", error);
                alert("Something went wrong. Please try again.");
            }
            setOpen(false);
            fetchSlots();
        } catch (error) {
            console.error("Error creating slot:", error);
        } finally {
            setUploading(false);
        }
    };


    const renewSlot = async (slot) => {
        try {
            const orderResponse = await fetch("https://streamneo.com/create-order", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
                body: JSON.stringify({
                    slotId: slot._id, // Replace with actual slot ID if available
                    duration: slot.duration,
                }),
            });

            const orderData = await orderResponse.json();
            if (!orderResponse.ok) throw new Error(orderData.message);

            const { order, paymentId } = orderData;

            // Step 2: Configure Razorpay options
            const options = {
                key: "rzp_test_IUYIAAIps3HYr2", // Replace with your Razorpay Key ID
                amount: order.amount,
                currency: order.currency,
                name: "StreamNeo",
                description: "Purchase Stream",
                order_id: order.id,
                handler: async (response) => {
                    // Step 3: Verify the payment
                    try {
                        const verificationResponse = await fetch("https://streamneo.com/verify-payment", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: localStorage.getItem("token"),
                            },
                            body: JSON.stringify({
                                paymentId: response.razorpay_payment_id,
                                orderId: response.razorpay_order_id,
                                signature: response.razorpay_signature,
                            }),
                        });

                        const verificationData = await verificationResponse.json();
                        if (!verificationResponse.ok) throw new Error(verificationData.message);

                        // Close the form and refresh slots
                        setOpen(false);
                        fetchSlots();
                        alert("Stream purchased successfully!");
                    } catch (error) {
                        console.error("Payment verification failed:", error);
                        alert("Payment verification failed. Please try again.");
                    }
                },
                prefill: {
                    name: localStorage.getItem("name"), // Replace with actual user data
                    email: localStorage.getItem("email"), // Replace with actual user data
                    contact: localStorage.getItem("mobile"), // Replace with actual user data
                },
                theme: {
                    color: "#3399cc",
                },
            };

            // Step 4: Open Razorpay Checkout
            const razorpay = new Razorpay(options);
            razorpay.open();
        } catch (error) {
            console.error("Error creating slot or initiating payment:", error);
            alert("Something went wrong. Please try again.");
        }
        fetchSlots();
    }

    const handleOpen = (videoUrl) => {
        setSelectedVideoUrl(videoUrl);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedVideoUrl("");
    };

    const handleCloseStreamDialog = () => {
        setDialogOpen(false);
        setSelectedVideo("");
        setStreamKey("");
    };

    const handleStartStream = async () => {
        if (selectedSlot.isActive) {
            // Stop stream logic
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                redirect: "follow"
            };
            await fetch(`https://server.streamneo.com/end-stream/${selectedSlot._id}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                })
                .catch(error => console.error(error));
        }
        try {
            const response = await fetch(`https://streamneo.com/server/start-stream/${selectedSlot._id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token")
                },
                body: JSON.stringify({ streamKey, videoId: selectedVideo, title: slotName })
            });
            const result = await response.text();

            fetchSlots();
            fetchVideos();
            handleCloseStreamDialog();
        } catch (error) {
            console.error("Error starting stream:", error);
        }
    };

    // Function to check if date is expired
    const isExpired = (expirationDate) => {
        return new Date(expirationDate) < new Date();
    };

    // Function to calculate remaining time
    const getRemainingTime = (expirationDate) => {
        const now = new Date();
        const expDate = new Date(expirationDate);
        const diffInMs = expDate - now;

        if (diffInMs <= 0) return null;

        const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
        if (days > 0) {
            return `${days}d ${hours}h ${minutes}m`;
        } else {
            return `${hours}h ${minutes}m`;
        }
    };



    if (loading) {
        return (<Container maxWidth="xl" sx={{
            py: 3,
        }}>
            <Paper
                elevation={0}
                sx={{
                    p: 3,
                    borderRadius: 3,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(10px)'
                }}>
                <Fade in timeout={1000}>
                    <Box>
                        <Typography
                            variant="h3"
                            align="start"
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                                background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                mb: 1,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Your Purchases
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            align="start"
                            gutterBottom
                            sx={{
                                mb: 4,
                                color: theme.palette.text.secondary,
                                fontSize: '1.15rem',
                                fontWeight: 500,
                                mx: 'auto',
                                fontFamily: 'Poppins'
                            }}
                        >
                            {localStorage.getItem("name").split(' ')[0]}! Here you can manage your slots and wallet balance.
                        </Typography>
                    </Box>
                </Fade>
                <CircularProgress size={40} thickness={4} />
            </Paper>
        </Container>
        );
    }

    return (
        <Container maxWidth="xl" sx={{
            py: 3,
        }}>
            <Grid container spacing={4}>
                {/* Left Side - Video Library */}
                <Grid item xs={12} md={9}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            borderRadius: 3,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            backdropFilter: 'blur(10px)'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                            <div style={{ flexDirection: 'column', justifyContent: 'start', alignItems: 'start', display: 'flex', alignItems: 'start' }}>
                                <Typography
                                    variant="h3"
                                    align="start"
                                    gutterBottom
                                    sx={{
                                        fontWeight: 700,
                                        background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        mb: 1,
                                        fontFamily: 'Poppins'
                                    }}
                                >
                                    Your Purchases
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    align="start"
                                    gutterBottom
                                    sx={{
                                        mb: 4,
                                        color: theme.palette.text.secondary,
                                        fontSize: '1.15rem',
                                        fontWeight: 500,
                                        mx: 'auto',
                                        fontFamily: 'Poppins'
                                    }}
                                >
                                    {localStorage.getItem("name").split(' ')[0]}! Here you can manage your slots and wallet balance.
                                </Typography>
                            </div>
                        </Box>
                        <Grid container spacing={3}>
                            <SlotsTable
                                slots={slots}
                                onStreamAction={(slot, type) => {
                                    renewSlot(slot);
                                }}
                                fetchSlots={fetchSlots}
                                fetchVideos={fetchVideos}
                            />
                        </Grid>
                    </Paper>
                </Grid>

                {/* Right Side - Purchase Section */}
                <Grid item xs={12} md={3}>
                    <Grow
                        in
                        timeout={1000}
                        style={{ transformOrigin: '0 0 0' }}
                        {...{ timeout: 1000 + (0 * 200) }}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                p: 3,
                                borderRadius: 3,
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                backdropFilter: 'blur(10px)'
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: 700,
                                    mb: 3,
                                    color: 'text.primary'
                                }}
                            >
                                Purchase Streams
                            </Typography>

                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mb: 4 }}
                            >
                                Every stream is a server instance that you can manage from this dashboard.
                            </Typography>

                            <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                <TextField
                                    label="Stream Title"
                                    value={newSlot.title}
                                    onChange={(e) => setNewSlot({ ...newSlot, title: e.target.value })}
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 2
                                        }
                                    }}
                                />

                                <FormControl fullWidth>
                                    <InputLabel>Quality</InputLabel>
                                    <Select
                                        value={newSlot.quality}
                                        onChange={(e) => setNewSlot({ ...newSlot, quality: e.target.value })}
                                        sx={{ borderRadius: 2 }}
                                    >
                                        <MenuItem value="720p">720p</MenuItem>
                                        <MenuItem value="1080p">1080p</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel>Duration</InputLabel>
                                    <Select
                                        value={newSlot.duration}
                                        onChange={(e) => setNewSlot({ ...newSlot, duration: e.target.value })}
                                        sx={{ borderRadius: 2 }}
                                    >
                                        <MenuItem value="1_day">1 Day</MenuItem>
                                        <MenuItem value="1_month">1 Month</MenuItem>
                                    </Select>
                                </FormControl>

                                <Button
                                    variant="contained"
                                    fullWidth
                                    size="large"
                                    onClick={handleAddSlot}
                                    startIcon={uploading ? null : <Add />}
                                    sx={{
                                        borderRadius: 2,
                                        py: 1.5,
                                        backgroundColor: 'primary.main',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                        textTransform: 'none',
                                        fontWeight: 600
                                    }}
                                >
                                    {uploading ? <CircularProgress size={20} thickness={4} color='white' /> : 'Purchase Stream'}
                                </Button>
                            </Box>
                        </Paper>
                    </Grow>
                </Grid>
            </Grid >
        </Container >
    );
}



const SlotsTable = ({ slots, onStreamAction, fetchSlots, fetchVideos }) => {
    const theme = useTheme();

    const isExpired = (expirationDate) => {
        return new Date(expirationDate) < new Date();
    };

    const getRemainingTime = (expirationDate) => {
        const now = new Date();
        const expDate = new Date(expirationDate);
        const diffInMs = expDate - now;

        if (diffInMs <= 0) return null;

        const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

        if (days > 0) {
            return `${days}d ${hours}h ${minutes}m`;
        } else {
            return `${hours}h ${minutes}m`;
        }
    };

    return (
        <Box sx={{ width: '100%', m: 2 }}>
            <Grow
                in
                timeout={1000}
                style={{ transformOrigin: '0 0 0' }}
                {...{ timeout: 1000 + (0 * 200) }}
            >
                <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{
                        borderRadius: 3,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        backdropFilter: 'blur(10px)',
                        overflow: 'hidden'
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                                <TableCell sx={{ fontWeight: 600 }}>Title</TableCell>
                                <TableCell sx={{ fontWeight: 600 }}>Quality</TableCell>
                                <TableCell sx={{ fontWeight: 600 }}>Plan</TableCell>
                                <TableCell sx={{ fontWeight: 600 }}>Time Remaining</TableCell>
                                <TableCell sx={{ fontWeight: 600 }}>Stream Key</TableCell>
                                <TableCell sx={{ fontWeight: 600 }} align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {slots.map((slot) => {
                                const expired = isExpired(slot.expirationDate);
                                const remainingTime = getRemainingTime(slot.expirationDate);

                                return (
                                    <TableRow
                                        key={slot._id}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                            },
                                            opacity: expired ? 0.7 : 1,
                                        }}
                                    >
                                        <TableCell>
                                            <Chip
                                                label={slot.isActive ? 'Active' : 'Inactive'}
                                                color={slot.isActive ? 'success' : 'default'}
                                                size="small"
                                                sx={{ borderRadius: 1 }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                                {slot.title}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={slot.quality || '720p'}
                                                size="small"
                                                sx={{
                                                    borderRadius: 1,
                                                    backgroundColor: theme.palette.grey[100],
                                                    color: theme.palette.text.primary
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                                {slot.duration === '1_day' ? '1 Day' : '1 Month'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={expired ? 'Expired' : remainingTime}
                                                color={expired ? 'error' : 'primary'}
                                                size="small"
                                                sx={{ borderRadius: 1 }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                                                {slot.streamKey}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            {expired ? (
                                                <Tooltip title="Renew Slot">
                                                    <IconButton
                                                        onClick={() => onStreamAction(slot, 'renew')}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: theme.palette.grey[100],
                                                            '&:hover': {
                                                                backgroundColor: theme.palette.grey[200],
                                                            },
                                                        }}
                                                    >
                                                        <Restore fontSize="small" color="primary" /> {/* Renew Icon */}
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Extend Slot">
                                                    <IconButton
                                                        onClick={() => onStreamAction(slot, 'extend')}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: theme.palette.grey[100],
                                                            '&:hover': {
                                                                backgroundColor: theme.palette.grey[200],
                                                            },
                                                        }}
                                                    >
                                                        <Add fontSize="small" color="primary" /> {/* Extend Icon */}
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grow>
        </Box>
    );
};

export default Billing;