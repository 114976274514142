import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Upload } from 'tus-js-client';
import { Circle } from 'lucide-react';
import {
    CircularProgress,
    IconButton,
    Modal,
    Backdrop,
    Fade,
    Button,
    Dialog,
    Grow,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Box,
    Container,
    Paper,
    Divider,
    Chip,
    useTheme
} from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import { PlayArrow, CloudUpload, Delete, Add } from '@mui/icons-material';
import SubscriptionsRoundedIcon from '@mui/icons-material/SubscriptionsRounded';
import StatusChip from './AnimatedStatus';

let socket = io('https://streamneo.com');

function SlotsScreen() {
    const theme = useTheme();

    const [newSlot, setNewSlot] = useState({
        title: "",
        quality: "720p",
        duration: "1_day"
    });
    const [slots, setSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState('');
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [title, setTitle] = useState("");
    const [videos, setVideos] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState("");
    const [streamKey, setStreamKey] = useState("");
    const [slotName, setSlotName] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchSlots();
        fetchVideos();
    }, []);

    const fetchSlots = async () => {
        try {
            const response = await fetch("https://streamneo.com/user/slots", {
                headers: { Authorization: localStorage.getItem("token") }
            });
            const data = await response.json();
            setSlots(data);
        } catch (error) {
            console.error("Error fetching slots:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchVideos = async () => {
        try {
            const response = await fetch('https://streamneo.com/user-videos', {
                headers: { 'Authorization': `${localStorage.getItem("token")}` }
            });
            if (response.ok) {
                const data = await response.json();
                setVideos(data.videos);
            } else {
                throw new Error('Failed to fetch videos');
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
    };

    const handleAddSlot = async () => {
        try {
            await fetch("https://streamneo.com/slots", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token")
                },
                body: JSON.stringify(newSlot)
            });
            setOpen(false);
            fetchSlots();
        } catch (error) {
            console.error("Error creating slot:", error);
        }
    };

    const handleOpen = (videoUrl) => {
        setSelectedVideoUrl(videoUrl);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedVideoUrl("");
    };

    const handleCloseStreamDialog = () => {
        setDialogOpen(false);
        setSelectedVideo("");
        setStreamKey("");
    };

    const handleStartStream = async () => {
        if (selectedSlot.isActive) {
            // Stop stream logic
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                redirect: "follow"
            };
            await fetch(`https://server.streamneo.com/end-stream/${selectedSlot._id}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                })
                .catch(error => console.error(error));
        }
        try {
            console.log(`https://streamneo.com/server/start-stream/${selectedSlot._id}`);

            const response = await fetch(`https://streamneo.com/server/start-stream/${selectedSlot._id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token")
                },
                body: JSON.stringify({ streamKey, videoId: selectedVideo, title: slotName })
            });
            const result = await response.text();

            fetchSlots();
            fetchVideos();
            handleCloseStreamDialog();
        } catch (error) {
            console.error("Error starting stream:", error);
        }
    };

    // Function to check if date is expired
    const isExpired = (expirationDate) => {
        return new Date(expirationDate) < new Date();
    };

    // Function to calculate remaining time
    const getRemainingTime = (expirationDate) => {
        const now = new Date();
        const expDate = new Date(expirationDate);
        const diffInMs = expDate - now;

        if (diffInMs <= 0) return null;

        const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
        if (days > 0) {
            return `${days}d ${hours}h ${minutes}m`;
        } else {
            return `${hours}h ${minutes}m`;
        }
    };


    if (loading) {
        return (<Container maxWidth="xl" sx={{
            py: 3,
        }}>
            <Paper
                elevation={0}
                sx={{
                    p: 3,
                    borderRadius: 3,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(10px)'
                }}>
                <Fade in timeout={1000}>
                    <Box>
                        <Typography
                            variant="h3"
                            align="start"
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                                background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                mb: 1,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Your Streams
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            align="start"
                            gutterBottom
                            sx={{
                                mb: 4,
                                color: theme.palette.text.secondary,
                                fontSize: '1.15rem',
                                fontWeight: 500,
                                mx: 'auto',
                                fontFamily: 'Poppins'
                            }}
                        >
                            {localStorage.getItem("name").split(' ')[0]}! Here are your available slots for video streaming.
                        </Typography>
                    </Box>
                </Fade>
                <CircularProgress size={40} thickness={4} />
            </Paper>
        </Container>
        );
    }

    const activeStreams = slots.filter((item) => item.isActive).length;
    const totalSlots = slots.length;
    
    return (
        <Container maxWidth="xl" sx={{
            py: 3,
        }}>
            <Grid container spacing={4}>
                {/* Left Side - Video Library */}
                <Grid item xs={12} md={12}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            borderRadius: 3,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            backdropFilter: 'blur(10px)'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                            <div style={{ flexDirection: 'column', justifyContent: 'start', display: 'flex', alignItems: 'start' }}>
                                <Typography
                                    variant="h3"
                                    align="start"
                                    gutterBottom
                                    sx={{
                                        fontWeight: 700,
                                        background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        mb: 1,
                                        fontFamily: 'Poppins'
                                    }}
                                >
                                    Your Streams
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    align="start"
                                    gutterBottom
                                    sx={{
                                        mb: 4,
                                        color: theme.palette.text.secondary,
                                        fontSize: '1.15rem',
                                        fontWeight: 500,
                                        mx: 'auto',
                                        fontFamily: 'Poppins'
                                    }}
                                >
                                    {localStorage.getItem("name").split(' ')[0]}! Here are your available slots for video streaming.
                                </Typography>
                            </div>
                            <StatusChip activeStreams={activeStreams} totalSlots={totalSlots} />
                        </Box>
                        <Grid container spacing={3}>
                            {slots.map((video, index) => {
                                const expired = isExpired(video.expirationDate);
                                const remainingTime = getRemainingTime(video.expirationDate);

                                return (
                                    <Grid item xs={12} sm={6} md={3} key={video._id}>
                                        <Grow
                                            in
                                            timeout={1000}
                                            style={{ transformOrigin: '0 0 0' }}
                                            {...{ timeout: 1000 + (index * 200) }}
                                        >
                                            <Card
                                                sx={{
                                                    borderRadius: 3,
                                                    boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
                                                    transition: 'all 0.3s ease',
                                                    '&:hover': {
                                                        transform: !expired ? 'translateY(-4px)' : 'none',
                                                        boxShadow: !expired ? '0 12px 32px rgba(0,0,0,0.12)' : '0 8px 24px rgba(0,0,0,0.08)',
                                                    },
                                                    position: 'relative',
                                                    height: 240,
                                                    overflow: 'hidden',
                                                    cursor: expired ? 'not-allowed' : 'pointer',
                                                    filter: expired ? 'grayscale(0.5)' : 'none',
                                                }}
                                                onClick={(e) => {
                                                    if (!expired) {
                                                        e.stopPropagation();
                                                        setDialogOpen(true);
                                                        setSelectedSlot(video);
                                                        setSlotName(video.title);
                                                        setStreamKey(video.streamKey);
                                                    }
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 16,
                                                        right: 16,
                                                        backgroundColor: expired ? 'error.main' : 'success.main',
                                                        color: 'white',
                                                        padding: '4px 8px',
                                                        borderRadius: 3,
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    <Typography variant="caption" fontWeight="bold">
                                                        {expired ? 'Expired' : remainingTime}
                                                    </Typography>
                                                </Box>

                                                {video.isActive ? (
                                                    <CardMedia
                                                        component="img"
                                                        image={`https://server.streamneo.com/${video.video.thumbnailPath.replace('/var/www/html/', '')}`}
                                                        alt={video.title}
                                                        sx={{
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            height: '100%',
                                                            background: 'linear-gradient(45deg, #f3f4f6 30%, #e5e7eb 90%)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="body1" color="text.secondary">
                                                            {expired ? 'Renew this stream from the billing tab.' : 'No Active Stream!'}
                                                        </Typography>
                                                    </Box>
                                                )}

                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)',
                                                        p: 2,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: 1
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{
                                                            color: 'white',
                                                            fontWeight: 600,
                                                            textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                                        }}
                                                    >
                                                        {video.title}
                                                    </Typography>
                                                    {!expired && (
                                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                                            <IconButton
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (video.isActive) {
                                                                        const requestOptions = {
                                                                            method: "POST",
                                                                            headers: { "Content-Type": "application/json" },
                                                                            redirect: "follow"
                                                                        };
                                                                        fetch(`https://server.streamneo.com/end-stream/${video._id}`, requestOptions)
                                                                            .then(response => response.text())
                                                                            .then(result => {
                                                                                fetchSlots();
                                                                                fetchVideos();
                                                                            })
                                                                            .catch(error => console.error(error));
                                                                    } else {
                                                                        setDialogOpen(true);
                                                                        setSelectedSlot(video);
                                                                        setSlotName(video.title);
                                                                        setStreamKey(video.streamKey);
                                                                    }
                                                                }}
                                                                sx={{
                                                                    backgroundColor: 'rgba(255,255,255,0.9)',
                                                                    '&:hover': {
                                                                        backgroundColor: 'white',
                                                                    },
                                                                }}
                                                            >
                                                                {video.isActive ? (
                                                                    <StopIcon color="error" />
                                                                ) : (
                                                                    <PlayArrow color="primary" />
                                                                )}
                                                            </IconButton>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Card>
                                        </Grow>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid >

            {/* Modals remain the same but with updated styling */}
            < Dialog
                open={dialogOpen}
                onClose={handleCloseStreamDialog}
                onAbort={handleCloseStreamDialog}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        maxHeight: '90vh'
                    }
                }
                }
            >
                <DialogTitle sx={{ pb: 1 }}>
                    <Typography variant="h5" fontWeight={700}>
                        Select Video and Stream Key
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>

                    <TextField
                        label="Slot Name"
                        value={slotName}
                        onChange={(e) => setSlotName(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ mb: 2 }}
                    />

                    <TextField
                        label="Stream Key"
                        value={streamKey}
                        onChange={(e) => setStreamKey(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                    <div style={{ height: '20px', width: '10px' }} />
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        {videos.map((video) => (
                            <Grid item xs={12} sm={6} md={4} key={video._id}>
                                <Card
                                    onClick={() => setSelectedVideo(video._id)}
                                    sx={{
                                        cursor: 'pointer',
                                        border: '2px solid',
                                        borderColor: selectedVideo === video._id ? 'primary.main' : 'transparent',
                                        borderRadius: 2,
                                        transition: 'all 0.2s ease',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                            boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                                        }
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        image={`https://server.streamneo.com/${video.thumbnailPath.replace('/var/www/html/', '')}`}
                                        alt={video.title}
                                        sx={{
                                            height: 160,
                                            objectFit: 'cover'
                                        }}
                                    />
                                    <CardContent>
                                        <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                                            {video.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Size: {video.videoSize}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>

                <DialogActions sx={{ p: 3 }}>
                    <Button
                        onClick={handleCloseStreamDialog}
                        variant="outlined"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleStartStream}
                        variant="contained"
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            px: 3
                        }}
                    >
                        {selectedVideo ? 'Start Stream' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog >
        </Container >
    );
}

export default SlotsScreen;