import React, { useEffect, useState } from 'react';
import { Box, Chip, Typography } from '@mui/material';
import { Circle as CircleIcon } from 'lucide-react';

const StatusChip = ({ activeStreams = 0, totalSlots = 5 }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsActive(prev => !prev);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        p: 2,
      }}
    >
      <Chip
        icon={
          <CircleIcon
            size={12}
            className={`${activeStreams > 0 ? 'animate-pulse' : ''}`}
            style={{
              color: activeStreams > 0 ? '#22c55e' : '#94a3b8'
            }}
          />
        }
        label={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body2" fontWeight="medium">
              {activeStreams} Active
            </Typography>
            <Typography
              variant="body2"
              sx={{ opacity: 0.7 }}
              color="text.secondary"
            >
              / {totalSlots} Total
            </Typography>
          </Box>
        }
        sx={{
          borderRadius: 2,
          px: 1,
          height: 36,
          backgroundColor: 'background.paper',
          border: '1px solid',
          borderColor: activeStreams > 0 ? 'success.main' : 'divider',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: 'background.paper',
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          },
          '& .MuiChip-label': {
            px: 1
          }
        }}
      />
      {activeStreams > 0 && (
        <Typography
          variant="caption"
          className="animate-fade"
          sx={{
            color: 'success.main',
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            '@keyframes fade': {
              '0%, 100%': {
                opacity: 0.7,
              },
              '50%': {
                opacity: 1,
              },
            },
            animation: 'fade 2s ease-in-out infinite',
          }}
        >
          Live Now
        </Typography>
      )}

      <style jsx global>{`
        @keyframes pulse {
          0%, 100% {
            opacity: 1;
            transform: scale(1);
          }
          50% {
            opacity: 0.5;
            transform: scale(1.1);
          }
        }
        
        .animate-pulse {
          animation: pulse 2s ease-in-out infinite;
        }
        
        @keyframes fade {
          0%, 100% {
            opacity: 0.7;
          }
          50% {
            opacity: 1;
          }
        }
        
        .animate-fade {
          animation: fade 2s ease-in-out infinite;
        }
      `}</style>
    </Box>
  );
};

export default StatusChip;