import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Paper, InputAdornment, IconButton } from '@mui/material';
import { Eye, EyeOff, Mail, LogIn } from 'lucide-react';
import { login } from '../api';

function Login() {
    const [form, setForm] = useState({ email: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await login(form);
            localStorage.setItem("token", response.token);
            localStorage.setItem("userId", response.user._id);
            localStorage.setItem("name", response.user.username);
            localStorage.setItem("email", response.user.email);
            localStorage.setItem("mobile", response.user.mobile);
            window.location.href = "/home";
        } catch (error) {
            console.error("Login error:", error);
            alert("Login failed. Check your credentials.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                minHeight: '100vh',
                background: 'linear-gradient(145deg, #f6f8ff 0%, #f0f3ff 100%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: { xs: 2, sm: 4 },
                fontFamily: 'Poppins, sans-serif', // Set Poppins as the default font
            }}
        >
            <Paper
                elevation={0}
                sx={{
                    width: '100%',
                    maxWidth: '1200px',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    borderRadius: '24px',
                    overflow: 'hidden',
                    boxShadow: '0 8px 40px rgba(0,0,0,0.08)'
                }}
            >
                {/* Left Section - Branding */}
                <Box
                    sx={{
                        flex: 1,
                        p: 6,
                        background: 'linear-gradient(135deg, #6a00ff 0%, #8f00ff 100%)',
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        fontFamily: 'Poppins, sans-serif', // Use Poppins for branding section
                    }}
                >
                    <Box>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 700,
                                mb: 2,
                                fontSize: { xs: '24px', md: '32px' }
                            }}
                        >
                            24/7 YouTube Live Stream
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{
                                mb: 4,
                                opacity: 0.9,
                                fontSize: { xs: '18px', md: '24px' }
                            }}
                        >
                            Pre-Recorded Videos
                        </Typography>
                        <Typography
                            sx={{
                                mb: 4,
                                opacity: 0.8,
                                lineHeight: 1.8
                            }}
                        >
                            Take your YouTube channel to the next level with Upstream.
                            The easiest way to build & maintain a 24-hour live stream using pre-recorded videos.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            p: 4,
                            borderRadius: '16px',
                            backdropFilter: 'blur(10px)'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontStyle: 'italic',
                                mb: 2,
                                lineHeight: 1.6
                            }}
                        >
                            "I'm impressed with the results I've seen since starting to use this product;
                            I began receiving clients and projects in the first week."
                        </Typography>
                        <Typography sx={{ opacity: 0.8 }}>
                            - Prasun Ranjan, Product Designer
                        </Typography>
                    </Box>
                </Box>

                {/* Right Section - Login Form */}
                <Box
                    sx={{
                        flex: 1,
                        p: { xs: 4, md: 6 },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        bgcolor: '#ffffff',
                        fontFamily: 'Roboto, sans-serif', // Use Roboto for the login form section
                    }}
                >
                    <Box sx={{ maxWidth: '400px', mx: 'auto', width: '100%' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#6a00ff',
                                fontWeight: 700,
                                mb: 1
                            }}
                        >
                            projectX
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 700,
                                mb: 1
                            }}
                        >
                            Welcome Back
                        </Typography>
                        <Typography
                            sx={{
                                color: 'text.secondary',
                                mb: 4
                            }}
                        >
                            Login to your account
                        </Typography>

                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                type="email"
                                margin="normal"
                                required
                                onChange={(e) => setForm({ ...form, email: e.target.value })}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Mail size={20} color="#666" />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    mb: 3,
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '12px',
                                    }
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Password"
                                variant="outlined"
                                type={showPassword ? 'text' : 'password'}
                                margin="normal"
                                required
                                onChange={(e) => setForm({ ...form, password: e.target.value })}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LogIn size={20} color="#666" />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                sx={{
                                    mb: 4,
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '12px',
                                    }
                                }}
                            />
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                disabled={isLoading || !form.email || !form.password}
                                sx={{
                                    bgcolor: '#6a00ff',
                                    borderRadius: '12px',
                                    py: 1.5,
                                    textTransform: 'none',
                                    fontSize: '16px',
                                    '&:hover': {
                                        bgcolor: '#5a00e6',
                                    },
                                    '&.Mui-disabled': {
                                        bgcolor: '#6a00ff',
                                        opacity: 0.6,
                                    }
                                }}
                            >
                                {isLoading ? "Logging in..." : "Sign In"}
                            </Button>

                            <Typography
                                align="center"
                                sx={{
                                    mt: 4,
                                    color: 'text.secondary'
                                }}
                            >
                                Don't have an account?{' '}
                                <Typography
                                    component="a"
                                    href="/register"
                                    sx={{
                                        color: '#6a00ff',
                                        textDecoration: 'none',
                                        fontWeight: 600,
                                        '&:hover': {
                                            textDecoration: 'underline'
                                        }
                                    }}
                                >
                                    Register
                                </Typography>
                            </Typography>
                        </form>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
}

export default Login;